import React from 'react';
import { Wrapper } from './Mouse.styled';
import Lottie from 'react-lottie';
import { handleOptionsLottie } from 'helper/lottie.config';
import MouseJSON from 'assets/mouse/mouse-click.json';

interface IPropsMouse {
  id: string;
}

export const Mouse = ({ id }: IPropsMouse) => {
  return (
    <Wrapper id={id}>
      <Lottie width={100} options={handleOptionsLottie(MouseJSON)} />
    </Wrapper>
  );
};
