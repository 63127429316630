import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
  width: 330px;
  height: 500px;
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid ${({ theme }: { theme: ITheme }) => theme.border1};
  backdrop-filter: blur(7.5px);
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  img {
    height: 286px;
    width: 286px;
    object-fit: cover;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    color: black;
    flex: 1;
    .card-title {
      font-weight: 500;
      font-size: 12px;
    }
    .card-subtitle {
      font-weight: 400;
      font-size: 10px;
      color: #515151;
    }
    &.right {
      text-align: right;
    }
  }
  .img-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text-container {
    }
  }
`;
