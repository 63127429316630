import React, { memo, useCallback } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { menus } from './Header-constants';
import { Wrapper } from './Header.styled';
import { Link, useHistory } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Switch } from '@mui/material';
import { Container } from '@mui/system';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import { darkModeSelector, switchTheme } from 'reducers';
import { onMoveAnimation } from 'services/hooks/useDevelopUI';
import { useOutsideClickRef } from 'services/hooks/useOutsideClickRef';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PieChartIcon from '@mui/icons-material/PieChart';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { formatAddressToHuman } from 'services/utilities/format';
import { Mouse } from 'components/Mouse/Mouse';
import { withAuth, withWallet } from 'hoc';
import classNames from 'classnames';
import MenuMobilePopUp from './MenuMobilePopUp';

const ModeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#3395f8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#003892',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#659cd3',
    borderRadius: 20 / 2,
  },
}));

const HeaderComponent = ({
  openConnectModal,
  closeConnectPopup,
  account,
  handleSignByWallet,
  token,
  onDisconnect,
}: any) => {
  const dispatch = useAppDispatch();
  const isDarkMode = useSelector(darkModeSelector);
  const refPopupConnect = React.useRef(null);

  const onToggleMode = useCallback(() => {
    dispatch(switchTheme());
  }, [dispatch]);
  const history = useHistory();

  useOutsideClickRef(refPopupConnect, () => {
    onMoveAnimation('wallet-status-popup', 'moveOutOpacity');
  });
  return (
    <Wrapper id="app-header-id">
      <MenuMobilePopUp />
      <Container className="container" maxWidth="xl">
        <div
          className="mobile-menu"
          onClick={() => {
            onMoveAnimation('mobile-menu-popup', 'moveInOpacity');
          }}
        >
          <MenuIcon />
          Menu
        </div>
        <img
          src={require(`assets/header/${
            isDarkMode ? 'logo' : 'logo-light'
          }.png`)}
          className="logo"
          alt="Logo"
          onClick={() => {
            history.push('/');
          }}
        />
        <div className="right-panel">
          <div className="menu">
            {menus.map((item) => (
              <Link key={item.name} to={item.path} className="menu-item">
                {item.name}
              </Link>
            ))}
          </div>
          {token && (
            <div className="account">
              <div className="title">
                <div className="avatar">
                  <AccountBoxIcon />
                </div>
                <span>Hi, {'Dotties'}</span>
                <KeyboardArrowDownIcon className="arrow" />
              </div>
              <div className="menu-container">
                <div className="menu">
                  <Link to="/" className="menu-item">
                    <PieChartIcon /> Dashboard
                  </Link>
                  <Link onClick={onDisconnect} to="/" className="menu-item">
                    <LogoutIcon /> Logout
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div
            className={`wallet-container ${!isDarkMode && 'light'}`}
            onClick={() => {
              onMoveAnimation('wallet-status-popup', 'moveInOpacity');
            }}
          >
            <AccountBalanceWalletIcon
              className={classNames('wallet-icon', {
                connected: account?.address,
              })}
            />
            <div
              className="wallet-status"
              ref={refPopupConnect}
              id="wallet-status-popup"
            >
              <div className="header">
                <span className="title">Your Wallet</span>
              </div>
              <span className="subtitle">
                Connecting your wallet is like “logging in” to Web3. Select your
                wallet from the options to get started.
              </span>
              {account?.address && (
                <div className="wallet-address">
                  <img src={require('assets/coins/aptos.png')} alt="Logo" />
                  <div className="address">
                    <div className="">
                      <span className="network">Aptos</span>
                    </div>
                    <span className="account">
                      {formatAddressToHuman(account?.address?.toString() || '')}
                    </span>
                  </div>
                </div>
              )}
              <div className="buttons">
                {!account?.address && (
                  <div
                    className="btn-disconnect connect"
                    onClick={() => {
                      setTimeout(closeConnectPopup);
                      openConnectModal();
                    }}
                  >
                    <div className="txt">Connect Wallet</div>
                    <Mouse id="mouse-connect" />
                  </div>
                )}
                {!token && account?.address && (
                  <div
                    className="btn-sign connect"
                    onClick={handleSignByWallet}
                  >
                    <div className="txt">Sign in</div>
                    <Mouse id="mouse-sign" />
                  </div>
                )}
                {account?.address && (
                  <div
                    className="btn-disconnect connect"
                    onClick={onDisconnect}
                  >
                    <div className="txt">Disconnect</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <ModeSwitch
            className="btn-mode-switch"
            onClick={onToggleMode}
            checked={isDarkMode}
          />
        </div>
      </Container>
    </Wrapper>
  );
};
export const Header = memo(withAuth(withWallet(HeaderComponent)));
