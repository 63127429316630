import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  position: fixed;
  height: 80px;
  z-index: ${({ theme }) => theme.zIndex10};
  border-bottom: 0.5px solid ${(props) => props.theme.text3};
  backdrop-filter: blur(6px);
  z-index: 1000;
  .account {
    position: relative;
    cursor: pointer;
    margin-right: 8px;
    &:hover .title {
      background: ${({ theme }: { theme: ITheme }) => theme.bgModalConnect};
      .arrow {
        transform: rotate(180deg);
      }
    }
    .title {
      display: flex;
      padding: 8px 10px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      border-radius: 40px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
           padding: 0px;
    `}
      span {
        font-weight: 500;
        font-size: 18px;
        margin-right: 5px;
        max-width: 183px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          display: none;
    `}
      }
      svg {
        transition: all 0.3s;
      }
      .avatar {
        border-radius: 50%;
        overflow: hidden;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          margin-right: 3px;
    `}
        background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%),
              linear-gradient(
                334.7deg,
                #7fc1dd 15.23%,
                #7d73f2 17.51%,
                #000000 24.08%
              );
        img {
          width: 38px;
          background-color: black;
          border-radius: 50%;
        }
      }
    }
    &:hover .menu-container {
      opacity: 1;
      visibility: visible;
    }
    .menu-container {
      position: absolute;
      padding-top: 12px;
      top: 52px;
      width: 100%;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
            width: 185px;
    `}
      .menu {
        width: 100%;
        background: ${({ theme }: { theme: ITheme }) => theme.bgModalConnect};
        border-radius: 20px;
        padding: 16px;
        a {
          text-decoration: none;
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          border-radius: 10px;
          transition: all 0.3s;
          width: 100%;
          svg {
            margin-right: 12px;
          }
          &:hover {
            background: rgba(155, 165, 177, 0.15);
          }
        }
      }
    }
  }
  .wallet-status {
    position: absolute;
    width: 450px;
    top: 47px;
    animation: moveOutOpacity;
    animation-fill-mode: forwards;
    transition: all 0.3s;
    margin-top: 10px;
    padding: 24px 24px 15px 24px;
    border-radius: 20px;
    right: 0px;
    max-width: 88vw;
    background: ${({ theme }: { theme: ITheme }) => theme.bgModalConnect};
    z-index: 10;
    display: flex;
    flex-direction: column;
    .subtitle {
      margin: 10px 0px;
    }
    .btn-disconnect {
      margin-bottom: 10px;
      padding: 12px 20px;
      width: 100%;
      text-align: center;
      background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%);
      border-radius: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      transition: all 0.2s;
      cursor: pointer;
      position: relative;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(1);
      }
    }
    .btn-sign {
      width: 100%;
      /* background-color: black; */
      border: 1px solid #c273f2;
      padding: 12px 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      border-radius: 20px;

      text-align: center;
      transition: all 0.3s;
      position: relative;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(1);
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      button {
        width: 100%;
      }
    }
    .wallet-address {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 18px;
      img {
        width: 24px;
        margin-right: 10px;
      }
      .address {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 500;
          font-size: 16px;
        }
        .account {
          color: rgba(155, 165, 177, 1);
          margin-top: 5px;
        }
      }
    }
    .title {
      font-weight: 600;
      font-size: 20px;
    }
  }
  .wallet-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      cursor: pointer;
      font-size: 30px;
    }
    .wallet-icon {
      font-size: 35px;
      margin-right: 10px;
    }

    .connected {
      color: #c273f2 !important;
    }
  }
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .mobile-menu {
      display: none;
      align-items: center;
      column-gap: 12px;
      ${(props) => props.theme.mediaWidth.upToSmall`
      
      display: flex;
      `}
    }
    .logo {
      cursor: pointer;
    }
  }
  .right-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    .menu {
      ${(props) => props.theme.mediaWidth.upToSmall`
      display: none;
      
      `}
      &-item {
        margin-right: 24px;
        font-weight: 400;
      }
    }
    .connect {
      .txt {
      }
    }
    .btn-mode-switch {
      ${(props) => props.theme.mediaWidth.upToSmall`
      position: fixed;
      right: 12px;
      top: 100px;
      
      `}
    }
  }
`;
