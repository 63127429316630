import { useAppDispatch } from 'app/store';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  darkModeSelector,
  removeToastPopup,
  selectToast,
  TOAST_STATUS,
} from 'reducers';
import { Wrapper } from './ToastPopup.styled';
import CloseIcon from '@mui/icons-material/Close';
import successAnimation from 'assets/common-icon/success-animation.json';
import errorAnimation from 'assets/common-icon/error-animation.json';
import Lottie from 'react-lottie';

const successOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const errorOptions = {
  loop: true,
  autoplay: true,
  animationData: errorAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ToastPopupComponent = () => {
  const dispatch = useAppDispatch();
  const handleCloseToastPopup = useCallback(() => {
    dispatch(removeToastPopup());
  }, [dispatch]);
  const isDarkMode = useSelector(darkModeSelector);
  const toast = useSelector(selectToast);

  return (
    <Wrapper id="toast-popup">
      <div
        className={classNames('overlay', {
          'overlay-white': !isDarkMode,
        })}
        onClick={handleCloseToastPopup}
      />
      <div className="toast">
        <div onClick={handleCloseToastPopup} className="close-icon">
          <CloseIcon />
        </div>
        <span className="title">{toast.title}</span>
        <div className="animation">
          {toast.status === TOAST_STATUS.SUCCESS ? (
            <Lottie options={errorOptions} />
          ) : (
            <Lottie options={errorOptions} />
          )}
        </div>
        <span className="message">{toast.description}</span>
      </div>
    </Wrapper>
  );
};

export const ToastPopup = memo(ToastPopupComponent);
