import { useCallback, useState } from 'react';

export function useOnOff(defaultVal = false) {
  const [isOn, setIsOn] = useState(defaultVal);
  const turnOn = useCallback(() => setIsOn(true), []);
  const turnOff = useCallback(() => setIsOn(false), []);

  return {
    isOn,
    turnOn,
    turnOff,
  };
}
