import React, { memo } from 'react';
import { Wrapper } from './Loading.styled';

const LoadingComponent = () => {
  return (
    <Wrapper id="loading">
      <img className="logo" src={require('assets/loading/logo.png')} alt="" />
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);
