import {
  AptosWalletAdapter,
  AptosWalletName,
  Coin98WalletAdapter,
  Coin98WalletName,
  FewchaWalletAdapter,
  MartianWalletAdapter,
  MartianWalletName,
  PontemWalletAdapter,
  PontemWalletName,
} from '@manahippo/aptos-wallet-adapter';

export const wallets = [
  new PontemWalletAdapter(),
  new MartianWalletAdapter(),
  new AptosWalletAdapter(),
  new FewchaWalletAdapter(),
  new Coin98WalletAdapter(),
];

export const WALLET_NAME = [
  {
    name: PontemWalletName,
    description: 'Mobile Wallet and Extension',
    image: 'pontem.svg',
  },
  {
    name: MartianWalletName,
    description: 'Mobile Wallet and Extension',
    image: 'martian.svg',
  },
  {
    name: AptosWalletName,
    description: 'Mobile Wallet and Extension',
    image: 'petra.svg',
  },

  {
    name: Coin98WalletName,
    description: 'Mobile Wallet and Extension',
    image: 'coin98.svg',
  },
];
