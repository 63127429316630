import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { selectToken, selectAddress } from 'reducers/account/account.selectors';
import { setAccessToken } from 'services/utilities/http';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { deleteAccount } from 'reducers/account';

export const AccessTokenProvider = ({ children }: { children: any }) => {
  const isFirstTimeRender = useRef(true);
  const { account } = useWallet();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const addressSigned = useSelector(selectAddress);
  useEffect(() => {
    setAccessToken(token);
  }, [token]);
  useEffect(() => {
    if (
      account?.publicKey &&
      addressSigned &&
      addressSigned !== account?.publicKey
    ) {
      dispatch(deleteAccount());
    }
    isFirstTimeRender.current = false;
  }, [account, addressSigned, dispatch]);

  return children;
};
