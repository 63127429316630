import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from 'reducers';
import { onMoveAnimation } from './useDevelopUI';

export const useAuth = () => {
  const token = useSelector(selectToken);
  const { account } = useWallet();
  const isValidInforRequest = token && account?.address;

  const openConnectPopup = useCallback(() => {
    onMoveAnimation('wallet-status-popup', 'moveInOpacity');
  }, []);
  const closeConnectPopup = useCallback(() => {
    onMoveAnimation('wallet-status-popup', 'moveOutOpacity');
  }, []);

  const checkValidInforRequest = useCallback(() => {
    if (!isValidInforRequest) {
      openConnectPopup();
    }
    return isValidInforRequest;
  }, [isValidInforRequest, openConnectPopup]);
  return {
    isValidInforRequest,
    checkValidInforRequest,
    openConnectPopup,
    closeConnectPopup,
    account,
    token,
  };
};
