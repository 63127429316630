import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/oauth/discord/success';

const HomepageRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('./Discord')),
  name: 'Discord OAuth2',
};

export default HomepageRoute;
