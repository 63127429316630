import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IStateRedirectRoute } from './redirect-route.entities';

const initialState: IStateRedirectRoute = {
  pathToRedirect: '',
};

const redirectRouteSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setRedirectRoute(
      state: IStateRedirectRoute,
      action: PayloadAction<IStateRedirectRoute>,
    ) {
      state.pathToRedirect = action.payload.pathToRedirect;
    },
    removeRedirectRoute(state: IStateRedirectRoute) {
      state.pathToRedirect = '';
    },
  },
});

export const { setRedirectRoute, removeRedirectRoute } =
  redirectRouteSlice.actions;

export const RedirectRouteReducer = redirectRouteSlice.reducer;
