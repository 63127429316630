import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  .toast {
    background: #2b2e39;
    padding: 30px 50px;
    width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    position: relative;
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: white;
    }
    .animation {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
    }
    .title {
      font-family: 'Big Shoulders Display';
      font-weight: 800;
      font-size: 40px;
      background: linear-gradient(90deg, #ff46c0 2.4%, #811fff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .message {
      font-weight: 400;
      font-size: 16px;
      color: white;
    }
  }
`;
