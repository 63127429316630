import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getAccessToken,
  getNonceRegistered,
  getSignature,
  registerAndGetNonce,
} from './account.service';
import { toast } from 'react-toastify';
import {
  SignMessagePayload,
  SignMessageResponse,
} from '@manahippo/aptos-wallet-adapter';

export interface IAccountState {
  token: string;
  expiresIn: string;
  pubkey?: string;
}

export interface IReponseWalletStatus {
  registered: boolean;
  nonce: string;
  message: string;
}

export interface IWalletProvider {
  address: string;
  publicKey: string;
  handleSignMessage: (
    message: string | SignMessagePayload | Uint8Array,
  ) => Promise<string | SignMessageResponse>;
}

const initialState: IAccountState = { token: '', expiresIn: '' };

export const signInByWallet = createAsyncThunk(
  'account/signinUserByWallet',
  async (wallet: IWalletProvider) => {
    console.log(wallet);
    try {
      if (!wallet.address) {
        throw new Error('Please connect your wallet');
      }
      const walletStatus: IReponseWalletStatus = await getNonceRegistered(
        wallet.address,
      );
      const isRegister = walletStatus.registered;
      let nonce = isRegister ? walletStatus.nonce : undefined;
      let message = isRegister ? walletStatus.message : undefined;
      if (!isRegister) {
        const resRegister: IReponseWalletStatus = await registerAndGetNonce(
          wallet.address,
        );
        if (!resRegister.nonce) {
          throw Error('Register wallet fail.');
        }
        const walletStatus: IReponseWalletStatus = await getNonceRegistered(
          wallet.address,
        );
        nonce = walletStatus.nonce;
        message = walletStatus.message;
      }

      if (!nonce) {
        throw new Error('Hmm! Cannot find your nonce');
      }
      const messageRequest = {
        nonce,
        message,
      };
      const responseSign: any = await getSignature(
        messageRequest,
        wallet.handleSignMessage,
      );

      if (!responseSign) {
        throw new Error('Hmm! Cannot find your signature');
      }
      const account: IAccountState = await getAccessToken(
        wallet.address,
        responseSign.signature,
        wallet.publicKey,
      );

      return { ...account, pubkey: wallet.publicKey };
    } catch (err: any) {
      toast.dismiss();
      toast.error(err?.message || err);
    }
  },
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAccount(state: IAccountState, action: PayloadAction<IAccountState>) {
      state.token = action.payload.token;
      state.expiresIn = action.payload.expiresIn;
      state.pubkey = action.payload.pubkey;
    },
    deleteAccount(state: IAccountState) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (state.token = ''), (state.expiresIn = ''), (state.pubkey = undefined);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signInByWallet.fulfilled,
      (state: IAccountState, action: any) => {
        // Add user to the state array
        state.token = action.payload.token;
        state.expiresIn = action.payload.expiresIn;
        state.pubkey = action.payload.pubkey;
      },
    );
  },
});

export const { updateAccount, deleteAccount } = accountSlice.actions;

export const AccountReducer = accountSlice.reducer;
