import {
  SignMessagePayload,
  SignMessageResponse,
} from '@manahippo/aptos-wallet-adapter';
import http from 'services/utilities/http';

export const getNonceRegistered = (address: string): any => {
  return http.get(`/auth/aptos/status?account=${address}`);
};

export const registerAndGetNonce = (address: string): any => {
  return http
    .post('/auth/aptos/register', {
      account: address,
    })
    .then((res) => res);
};

export const getSignature = async (
  message: any,
  handleSignMessage: (
    message: string | SignMessagePayload | Uint8Array,
  ) => Promise<string | SignMessageResponse>,
): Promise<string | SignMessageResponse> => {
  return new Promise((resolve, reject) => {
    handleSignMessage(message)
      .then((value) => resolve(value))
      .catch((err) => reject(err));
  });
};

export const getAccessToken = (
  address: string,
  signature: string,
  publicKey: string,
): any => {
  return http
    .post('auth/aptos/login', {
      pubkey: publicKey,
      account: address,
      signature: signature,
    })
    .then((res) => res);
};
