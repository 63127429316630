import React, { useMemo } from 'react';
import {
  ITheme,
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
} from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from './Theme.styled';
import { darkModeSelector } from './Theme.selector';

export const MEDIA_WIDTHS = {
  // upToExtraSmall: 500,
  // upToSmall: 720,
  // upToMedium: 1024,
  // upToLarge: 1480,
  upToExtraSmall2: 420,
  upToExtraSmall1: 500,
  upToExtraSmall: 640,
  upToSmall: 768,
  upToMedium2: 800,
  upToMedium: 1056,
  upToMedium1: 1156,
  upToLarge: 1460,
  uptoVeryLarge: 1600,
  upToLarge1: 1260,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const white = '#FFFFFF';
const black = '#000000';

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,
    baseBg: darkMode ? '#161616' : white,
    colorLight1: darkMode ? 'rgba(255, 255, 255, 0.1)' : white,

    // table
    headerRow: darkMode ? '#f5f5f5' : '#f5f5f5',
    hoverRow: darkMode ? '#e0e0e0' : '#e0e0e0',
    darkRow: darkMode ? '#F5F5F5' : '#F5F5F5',
    lightRow: darkMode ? white : white,

    // modal
    modalBg: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',

    border1: darkMode ? white : '#3548F0',
    border2: darkMode ? '#E0E0E0' : '#E0E0E0',

    // text
    text1: darkMode ? white : black,
    text2: darkMode ? '#D0D1DA' : 'rgba(112, 112, 112, 1)',
    text3: darkMode ? 'rgba(255, 255, 255,0.5)' : 'rgba(0, 0, 0, 0.5)',
    text4: darkMode ? 'rgba(255, 70, 192, 1)' : 'rgba(255, 70, 192, 1)',
    // Move to here
    text5: darkMode ? 'rgba(0, 0, 0, 0.2)' : '#2B2E39;',
    text6: darkMode ? '#FFFFFF' : 'rgba(43, 46, 57, 0.2);',
    text7: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)',
    text8: darkMode ? ' #FF46C0' : 'black',
    text9: darkMode
      ? 'rgba(255, 255, 255, 0.15);'
      : 'rgba(255, 255, 255, 0.7);',
    text10: darkMode ? 'rgba(255, 255, 255, 0.8);' : '#4F4F4F',

    //BG
    bg1: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)',
    bg2: darkMode ? 'rgba(255, 255, 255, 0.35)' : 'rgba(139, 132, 132, 0.3)',
    bg3: darkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.6)',
    bg4: darkMode ? 'rgba(0, 0, 0, 0.5)' : '#1A1D26',
    bg5: darkMode ? 'rgba(0, 0, 0, 0.6);' : '#2B2E39',
    bg6: darkMode ? 'rgba(0, 0, 0, 0.2);' : 'rgba(255, 255, 255, 0.2);',
    bg7: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#2B2E39;',
    bgModalConnect: darkMode
      ? 'rgba(3, 3, 3, 0.7)'
      : 'rgba(210, 210, 210, 0.8)',
    bg12: darkMode ? '#2b2e39' : '#1A1D26',
    green1: darkMode ? 'rgba(49, 217, 46, 1)' : 'rgba(49, 217, 46, 1)',
    green2: darkMode ? '#45B26B' : '#45B26B',
    red1: darkMode ? '#EF466F' : '#EF466F',

    yellow1: darkMode ? '#FFD166' : '#FFD166',
    yellow2: darkMode ? '#FF8A00' : '#FF8A00',

    tooltipBg: black,
    tooltipText: white,

    gray1: darkMode ? '#D8D8D8' : '#D8D8D8',
    gray2: darkMode ? '#D6D8E0' : '#D6D8E0',
    gray3: darkMode ? '#D1D1D1' : '#D1D1D1',

    dark1: darkMode ? '#161616' : '#232630',
    dark2: darkMode ? '#353945' : '#353945',
    dark3: darkMode ? '#777E90' : '#777E90',
    dark4: darkMode ? '#B1B5C3' : '#B1B5C3',
    dark5: darkMode ? '#232323' : '#232323',
    dark6: darkMode ? '#777E91' : '#777E91',
    dark7: darkMode ? '#23262F' : '#23262F',
    dark8: darkMode ? '#141416' : '#141416',
    dark9: darkMode ? '#202231' : '#202231',

    blue1: darkMode ? '#3772ff' : '#3772ff',
    blue2: darkMode ? '#8084dc' : '#8084dc',
    blue3: darkMode ? 'rgba(98, 216, 238, 1)' : 'rgba(98, 216, 238, 1)',
    blue4: darkMode ? '#15B2F4' : '#15B2F4',
  };
}

export function appTheme(darkMode: boolean): ITheme {
  return {
    ...colors(darkMode),
    zIndex1: 1,
    zIndex10: 10,
    zIndex20: 20,
    zIndex30: 30,
    zIndex40: 40,
    zIndex50: 50,
    zIndex60: 60,
    zIndex70: 70,
    zIndex80: 80,

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const darkMode = useSelector(darkModeSelector);

  const themeObject = useMemo(() => appTheme(darkMode), [darkMode]);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
};

export const FixedGlobalStyle = createGlobalStyle`
/* html{position: relative; height: 100%; overflow: hidden;} */
    html,
    body {
        margin: 0;
        padding: 0;
    }
    ::-webkit-scrollbar {
        width: 7px;
        background: #000;
    }

    ::-webkit-scrollbar-thumb {
        // background: #393812;
        background: linear-gradient(238.93deg, #90caffd6 18.8%, #07ff889e 81.2%);
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    ::-webkit-scrollbar-corner {
        background: #000;
    }
    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-content {
        background-color: transparent;
    }

    a {
        color: ${colors(false).black};
    }

    .modified-item:hover {
        border-bottom: 2px solid transparent !important;
        color: inherit !important;
    }

    * {
        box-sizing: border-box;
    }

    button {
        user-select: none;
    }
    .container {
        max-width: 1660px !important;
        padding: 0 16px !important;

    }

    html {
        font-size: 16px;
        font-variant: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
    }
    p {
        margin-bottom: 0;
    }
`;

export const ThemedGlobalStyle = createGlobalStyle`
    html {
        background: ${({ theme }: { theme: ITheme }) => theme.baseBg};
    }
    
    body {
        display: flex;
        margin: 0;
        min-height: 100vh;
        padding: 0;
        font-family: 'Poppins';
        color: ${({ theme }: { theme: ITheme }) => theme.text1};
        .btn-primary {
            color: ${({ theme }: { theme: ITheme }) => theme.text1};
            border-color: ${({ theme }: { theme: ITheme }) => theme.text1};
            text-transform: capitalize;
            font-family: 'Poppins';
        }

    &::-webkit-scrollbar {
      display: none;
    }
    }
    a {
        color: ${({ theme }: { theme: ITheme }) => theme.text1};
        text-decoration: none;
    }
    

    
    video::-webkit-media-controls, video::-moz-media-controls, video::-o-media-controls, video::-ms-media-controls {   display: none !important; }
`;
