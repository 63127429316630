import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/create-pool';

const CreatePoolRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('./CreatePool')),
  name: 'Pools',
};

export default CreatePoolRoute;
