import React from 'react';
import { Provider as ProviderRedux } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../services/utilities/queryClient';
import { toast, ToastContainer } from 'react-toastify';
import { MainRoute } from '../modules';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, ThemedGlobalStyle } from '../reducers/theme';
import { AccessTokenProvider, LoadingProvider } from 'providers';
import { ConnectForm, Header, ToastPopup } from 'components';
import { WalletProvider } from '@manahippo/aptos-wallet-adapter';
import { wallets } from 'configs/wallet/wallet';
import ScrollToTop from 'components/ScrollTop/ScrollTop';

let persistor = persistStore(store);

export const App = () => {
  return (
    <WalletProvider
      autoConnect
      wallets={wallets}
      onError={(error: Error) => {
        toast.dismiss();
        toast.error(error.message);
      }}
    >
      <ProviderRedux store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <ThemedGlobalStyle />
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AccessTokenProvider>
                  <LoadingProvider>
                    <ToastPopup />
                    <ConnectForm />
                    <Router>
                      <ScrollToTop />
                      <Header />
                      <MainRoute />
                      <ToastContainer
                        toastStyle={{ backgroundColor: 'black' }}
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnFocusLoss
                        pauseOnHover
                      />
                    </Router>
                  </LoadingProvider>
                </AccessTokenProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </ThemeProvider>
        </PersistGate>
      </ProviderRedux>
    </WalletProvider>
  );
};
