export const formatAddressToHuman = (address: string | undefined) => {
  if (address) {
    return (
      address?.substring(0, 7) +
      '...' +
      address?.substring(address.length - 7, address.length)
    );
  }
  return '...';
};
