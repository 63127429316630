import React from 'react';
import { menus } from './Header-constants';
import { Wrapper } from './MenuMobilePopUp.styled';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { darkModeSelector } from 'reducers';
import { onMoveAnimation } from 'services/hooks';

const MenuMobilePopUp = () => {
  const isDarkMode = useSelector(darkModeSelector);

  return (
    <Wrapper id="mobile-menu-popup">
      <div
        onClick={() => onMoveAnimation('mobile-menu-popup', 'moveOutOpacity')}
        className={classNames('overlay', {
          'overlay-white': !isDarkMode,
        })}
      ></div>
      <div
        className="menu-form"
        onClick={() => {
          onMoveAnimation('mobile-menu-popup', 'moveOutOpacity');
        }}
      >
        <Link to="/" className="menu-item">
          Home
        </Link>
        {menus.map((item) => (
          <Link key={item.name} to={item.path} className="menu-item">
            {item.name}
          </Link>
        ))}
      </div>
    </Wrapper>
  );
};

export default MenuMobilePopUp;
