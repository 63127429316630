import { Wrapper } from './CustomCarousel.styled';
import Carousel from 'react-spring-3d-carousel';
import { memo, useCallback } from 'react';
import { config } from 'react-spring';

interface IPropsCustomCarousel {
  slides: any[];
  offsetRadius?: number;
  slide: number;
  setActiveSlide: (active: number) => void;
}
const CustomCarouselComponent = ({
  slides,
  offsetRadius,
  slide,
  setActiveSlide,
}: IPropsCustomCarousel) => {
  const onHandleNext = useCallback(() => {
    setActiveSlide(slide + 1);
  }, [setActiveSlide, slide]);
  const onHandlePrev = useCallback(() => {
    setActiveSlide(slide - 1);
  }, [setActiveSlide, slide]);

  return (
    <Wrapper>
      <img
        className="btn btn-prev"
        onClick={onHandlePrev}
        src={require('assets/home/arrow.png')}
        alt="Arrow"
      />
      <img
        className="btn btn-next"
        onClick={onHandleNext}
        src={require('assets/home/arrow.png')}
        alt="Arrow"
      />
      <Carousel
        slides={slides}
        offsetRadius={offsetRadius}
        showNavigation={false}
        goToSlide={slide}
        animationConfig={config.gentle}
      />
    </Wrapper>
  );
};

export const CustomCarousel = memo(CustomCarouselComponent);
