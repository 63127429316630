import { memo } from 'react';
import { tags } from './Branding.constants';
import { Wrapper } from './Branding.styled';
const BrandingComponent = () => {
  return (
    <Wrapper>
      <span className="title">Upcoming collections on Dotties</span>
      <span className="subtitle">(Coming soon)</span>
      <div className="tags-container">
        <div className="tags">
          {tags.map((tag, index) => (
            <div key={index} className="tag">
              <img src={require(`assets/home/tag${tag.icon}`)} alt="Tag1" />
              <span className="name">{tag.name}</span>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
export const Branding = memo(BrandingComponent);
