import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

export const ButtonCustomDefault = styled(LoadingButton)`
  background: rgba(255, 70, 192, 0.9) !important;
  text-transform: capitalize !important;
  &.MuiLoadingButton-loading {
    opacity: 0.5 !important;
  }
`;
