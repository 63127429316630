import { TextField } from '@mui/material';
import styled from 'styled-components';

export const TextFieldDefault = styled(TextField)`
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  color: white;
  .MuiFormLabel-root {
    color: #a4a4a4 !important;
  }
  .MuiInputBase-root {
    &.Mui-error {
      fieldset {
        border-color: #de4141 !important;
      }
    }
    fieldset {
      background: rgba(255, 255, 255, 0.1) !important;
      border: 1px solid rgba(255, 255, 255, 0.6) !important;
    }
    input {
      color: white;
      font-family: 'Poppins';
      font-size: 14px;
    }
  }
`;
