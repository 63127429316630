import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { dispatch } from 'app/store';
import { FunctionComponent, useCallback } from 'react';
import { selectToken, signInByWallet } from 'reducers';
import { useSelector } from 'react-redux';

export interface IStateWithAuth {
  handleSignByWallet: () => any;
}

export const withAuth =
  <T,>(WrappedComponent: FunctionComponent<T & IStateWithAuth>) =>
  (props: T) => {
    const token = useSelector(selectToken);
    const { account, signMessage } = useWallet();
    const handleSignByWallet = useCallback(async () => {
      const resp = await dispatch(
        signInByWallet({
          publicKey: account?.publicKey?.toString() || '',
          handleSignMessage: signMessage,
          address: account?.address?.toString() || '',
        }),
      );
      console.log(resp);
    }, [account?.address, account?.publicKey, signMessage]);

    return (
      <WrappedComponent
        {...{
          ...props,
          handleSignByWallet,
          token,
        }}
      />
    );
  };
