import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  .title {
    font-family: 'Big Shoulders Display';
    font-weight: 600;
    margin: 0;
    font-size: 32px;
    ${(props) => props.theme.mediaWidth.upToSmall`
          font-size: 28px;
    `}
  }
  .subtitle {
    max-width: 600px;
    margin: 8px 0px;
    ${(props) => props.theme.mediaWidth.upToSmall`
          font-size: 14px;
    `}
  }
  .tags-container {
    max-width: calc(100vw - 30px);
    overflow-x: auto;
    .tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;
      ${(props) => props.theme.mediaWidth.upToSmall`
            justify-content: flex-start;
      
        `}
      .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border: 0.5px solid rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(2px);
        background-color: ${({ theme }: { theme: ITheme }) =>
          theme.colorLight1};
        border-radius: 4px;
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
            width: 100%;
        `}
        img {
          width: 32px;
          margin-right: 8px;
          ${(props) => props.theme.mediaWidth.upToExtraSmall1`
            width: 30px;
            `}
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          ${(props) => props.theme.mediaWidth.upToExtraSmall1`
            font-size:14px;
            `}
        }
      }
    }
  }
`;
