export interface IMenuItem {
  path: string;
  name: string;
}

export const menus: IMenuItem[] = [
  {
    path: '/genesis-drop',
    name: 'Genesis Drop ',
  },
  // {
  //   path: '/collection',
  //   name: 'Collection',
  // },
  // {
  //   path: '/pool',
  //   name: 'Pool',
  // },
  // {
  //   path: '/real-yield',
  //   name: 'Real Yield',
  // },
];
