export const tags = [
  { name: 'Blue Collection', icon: '1.png' },
  { name: 'Digital Resting', icon: '2.png' },
  { name: 'Blue Collection', icon: '3.png' },
  { name: 'LE FLOAT', icon: '4.png' },
  { name: 'NFTubers', icon: '5.png' },
  { name: 'Magazine Cover', icon: '6.png' },
  { name: 'NFTubers', icon: '7.png' },
  { name: 'LE FLOAT', icon: '8.png' },
  { name: 'Blue Collection', icon: '9.png' },
  { name: 'Mecha Maids', icon: '10.png' },
  { name: 'Blue Collection', icon: '11.png' },
  { name: 'Magazine Cover', icon: '12.png' },
  { name: 'Digital Resting Point', icon: '13.gif' },
  { name: 'Collection', icon: '14.png' },
];
