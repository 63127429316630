import React, { memo } from 'react';
import { Wrapper } from './Card.styled';

interface IPropsCard {
  image?: string;
  title?: string;
  volume?: string;
  avatar?: string;
  owner?: string;
  price?: string;
  preImage?: string;
}

const CardComponent = ({
  title,
  image,
  volume,
  avatar,
  owner,
  price,
  preImage,
}: IPropsCard) => {
  return (
    <Wrapper>
      <img
        src={
          preImage
            ? preImage
            : image
            ? require(`assets/home/${image}.png`)
            : require(`assets/growth-hack/card-1.png`)
        }
        alt={owner}
      />
      <div className="info">
        <div className="text-container">
          <span className="card-title">{title}</span>
          <span className="card-subtitle">{image}</span>
        </div>
        {/* <div className="text-container right">
          <span className="card-title">Volume</span>
          <span className="card-subtitle">{volume} APT</span>
        </div> */}
      </div>
      {/* <div className="info">
        <div className="text-container">
          <span className="card-subtitle">Creator</span>
          <span className="card-title">{formatAddressToHuman(owner)}</span>
        </div>
        <div className="text-container right">
          <span className="card-subtitle">Floor Price</span>
          <span className="card-title">{price} APT</span>
        </div>
      </div> */}
    </Wrapper>
  );
};

export const Card = memo(CardComponent);
