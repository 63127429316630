import {
  AccountKeys,
  useWallet,
  WalletName,
} from '@manahippo/aptos-wallet-adapter';
import { useAppDispatch } from 'app/store';
import { FunctionComponent, useCallback } from 'react';
import { deleteAccount } from 'reducers';
import { onMoveAnimation } from 'services/hooks/useDevelopUI';

export interface IStateWithWallet {
  connect: (walletName?: WalletName<string> | undefined) => Promise<void>;
  connected: boolean;
  account: AccountKeys | null;
  disconnect: () => Promise<void>;
  connecting: boolean;
  disconnecting: boolean;
  openConnectModal: () => void;
  closeConnectModal: () => void;
  openConnectPopup: () => void;
  closeConnectPopup: () => void;
}

export const withWallet =
  <T,>(WrappedComponent: FunctionComponent<T & IStateWithWallet>) =>
  (props: T) => {
    const {
      connect,
      connected,
      account,
      disconnect,
      connecting,
      disconnecting,
    } = useWallet();
    const dispatch = useAppDispatch();
    const openConnectModal = useCallback(() => {
      onMoveAnimation('wallet-status-popup', 'moveOutOpacity');
      onMoveAnimation('connect-wallet-overlay', 'moveInOpacity');
    }, []);

    const closeConnectModal = useCallback(
      () => onMoveAnimation('connect-wallet-overlay', 'moveOutOpacity'),
      [],
    );
    const openConnectPopup = useCallback(() => {
      onMoveAnimation('wallet-status-popup', 'moveInOpacity');
    }, []);
    const closeConnectPopup = useCallback(() => {
      onMoveAnimation('wallet-status-popup', 'moveOutOpacity');
    }, []);
    const onDisconnect = useCallback(() => {
      disconnect();
      dispatch(deleteAccount());
    }, [disconnect, dispatch]);

    return (
      <WrappedComponent
        {...{
          ...props,
          connect,
          connected,
          account,
          disconnect,
          connecting,
          disconnecting,
          openConnectModal,
          closeConnectModal,
          onDisconnect,
          openConnectPopup,
          closeConnectPopup,
        }}
      />
    );
  };
