import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s;
  overflow: visible;
  .logo {
    animation: fade 0.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
`;
