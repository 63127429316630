import styled from 'styled-components';

export const Wrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
  .left-step {
    width: 35%;
    padding: 40px 34px;
    background: #2b2e39;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 20px 0px 0px 20px;
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
    display: none;
    `}
    .left-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
    }
    .left-small-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .left-subtitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      margin-top: 9px;
    }
    img {
      margin-left: -11px;
    }
    .step {
      margin-top: 21px;
    }
    .data-table {
      width: 140px;
    }
    .data-table .table-head {
      display: flex;
      position: relative;
      justify-content: space-between;
      .link-ends {
        z-index: 2;
        position: absolute;
        top: 50%;
        transition: all 1.5s ease-in-out;
        left: 0%;
        width: 100%;
        height: 2px;
        background: #ff46c0;
        border-radius: 4px;
        transform: translateY(-50%);
      }
      .link-full {
        z-index: 1;
        position: absolute;
        top: 50%;
        transition: all 1.5s ease-in-out;
        left: 0%;
        width: 100%;
        height: 2px;
        background: #b5b4b4;
        border-radius: 4px;
        transform: translateY(-50%);
      }
      .dot {
        position: relative;
        z-index: 3;
        width: 10px;
        height: 10px;
        background: #ffffff;

        border-radius: 50%;
        &.active {
          background: #ff46c0;
        }
      }

      display: flex;
      align-items: center;
    }
  }
  .right-wallet-form {
    position: relative;
    background: #1a1d26;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-left: none;
    border-radius: 0px 20px 20px 0px;
    padding: 24px 26px;
    width: 65%;
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
    width: 100%;
    border-left: unset;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 20px;
    `}
    .btn-close {
      position: absolute;
      right: 16px;
      top: 16px;
      background: #ffffff;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      svg {
        color: black;
      }
    }
    .reject {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .btn-back {
        align-self: center;
        background: rgba(255, 70, 192, 0.5);
        border: 1px solid rgba(255, 70, 192, 0.6);
        border-radius: 30px;
        padding: 12px 16px;
        margin-bottom: 12px;
        cursor: pointer;
      }
    }
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      display: flex;
      flex-direction: column;

      ${(props) => props.theme.mediaWidth.upToExtraSmall`
        font-size: 24px;
            `}
      ${(props) => props.theme.mediaWidth.upToExtraSmall`
        font-size: 16px;
            `}
    }
    .wallet-connect {
      display: flex;
      align-items: center;
      justify-content: center;
      > div.main-wallet {
        display: flex;
        flex-direction: column;
        width: 100%;
        ${(props) => props.theme.mediaWidth.upToExtraSmall2`
       width: 95vw;
            `}
      }
    }
    .btn-connect {
      padding: 27px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background: rgba(255, 255, 255, 0.1);
      border: 0.5px solid rgba(255, 255, 255, 0.6);
      border-radius: 10px;
      margin-top: 52px;
      cursor: pointer;
      img {
        width: 40px;
      }
      span {
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
      }
    }
    .btn-connect-1 {
      padding: 27px 30px;
      max-width: 421px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      background: rgba(255, 255, 255, 0.1);
      border: 0.5px solid rgba(255, 255, 255, 0.6);
      border-radius: 10px;
      margin-top: 22px;
      cursor: pointer;
      img {
        width: 40px;
      }
      > span {
        font-size: 18px;
        color: #ffffff;
        > span {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .form {
    width: 30%;
    min-width: 850px;
    max-height: 80%;
    overflow-y: scroll;
    display: flex;
    min-height: min(90vh, 478px);
    ${(props) => props.theme.mediaWidth.upToMedium`
    width: 95%;
    min-width: unset;
    `}
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
      padding: 20px 10px;
    `}
    ${(props) => props.theme.mediaWidth.upToExtraSmall2`
      height:75%;
    `}
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .intro {
      font-weight: 400;
      font-size: 16px;
      align-self: center;
      margin-top: 20px;
      margin-bottom: 23px;
    }
    .connect-type {
      display: flex;
      background: rgba(255, 255, 255, 0.1);
      border: 0.5px solid rgba(255, 255, 255, 0.6);
      border-radius: 4px;
      padding: 6px 17px;
      align-items: center;
      width: fit-content;
      align-self: center;
      margin-top: 20px;
      div {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        padding: 3px 25px;
        transition: all 0.3s;
        &.active {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
        }
      }
    }
    .header {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      .title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        display: flex;
        flex-direction: column;

        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        font-size: 24px;
            `}
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        font-size: 16px;
            `}
      }
      .icon {
        color: white;
      }
    }
    .wallet-address {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 18px;
      margin-top: 14px;
      img {
        width: 30px;
        margin-right: 10px;
      }
      .address {
        display: flex;
        flex-direction: column;
        span {
          font-family: 'Poppins';
          font-weight: 500;
          font-size: 20px;
        }
        .account {
          color: rgba(155, 165, 177, 1);
          margin-top: 5px;
        }
      }
    }
    .connectors {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      margin-top: 12px;

      ${(props) => props.theme.mediaWidth.upToExtraSmall2`
      margin-top: 30px;
      grid-template-columns: 1fr;
            `}
      .connector {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 0.5px solid rgba(255, 255, 255, 0.4);
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        padding: 12px 4px;
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
        .wallet-image {
          width: 44px;
          ${(props) => props.theme.mediaWidth.upToExtraSmall`
          width: 32px;
          `}
          ${(props) => props.theme.mediaWidth.upToExtraSmall`
          width: 32px;
          `}
        }
        .connect {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          ${(props) => props.theme.mediaWidth.upToExtraSmall`

          margin-left: 12px;
            `}
          ${(props) => props.theme.mediaWidth.upToExtraSmall2`
          flex-direction: column;
          align-items: flex-start;
          row-gap: 12px;

            `}
          .description {
            display: flex;
            flex-direction: column;
            .title {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              color: #ffffff;
              ${(props) => props.theme.mediaWidth.upToExtraSmall`
              font-size: 14px;
            `}
            }
            .subtitle {
              color: #979ba5;
              font-size: 16px;
              margin-top: 7px;
              ${(props) => props.theme.mediaWidth.upToExtraSmall`
              font-size: 12px;
            `}
            }
          }
          .button {
            border: none;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 12px;
            &:hover {
              background-color: unset;
            }
            .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
              color: rgba(255, 255, 255, 0.4);
            }
          }
        }
      }
    }
  }
`;
