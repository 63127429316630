import React, { memo, useState } from 'react';
import { Wrapper } from './ConnectForm.styled';
import LoadingButton from '@mui/lab/LoadingButton';
import { withAuth, withWallet } from 'hoc';
import { WALLET_NAME } from 'configs/wallet/wallet';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { darkModeSelector } from 'reducers';

const ConnectFormComponent = withAuth(
  withWallet(({ connect, closeConnectModal, account }) => {
    const isDarkMode = useSelector(darkModeSelector);
    const [loading, setLoading] = useState(-1);
    React.useEffect(() => {
      account?.address &&
        setTimeout(() => {
          closeConnectModal();
        });
    }, [account, closeConnectModal]);
    return (
      <Wrapper id="connect-wallet-overlay">
        <div
          className={classNames('overlay', {
            'overlay-white': !isDarkMode,
          })}
          onClick={closeConnectModal}
        />
        <div className="form">
          <div className="left-step">
            <img src={require(`assets/header/logo.png`)} alt="Logo" />
            <div className="left-title">Get started</div>
            <div className="left-small-title">Connect your wallet</div>
            <div className="left-subtitle">
              Connecting your wallet is like “logging in” to Web3. Select your
              wallet from the options to get started.
            </div>
          </div>
          <div className="right-wallet-form">
            <div className="btn-close" onClick={closeConnectModal}>
              <CloseIcon />
            </div>
            <div>
              <div className="title">Available Wallets</div>
              <div className="wallet-connect">
                <div
                  className={classNames('overlay', {
                    'overlay-white': !isDarkMode,
                  })}
                  onClick={closeConnectModal}
                />
                <div className="main-wallet">
                  <div className="connectors">
                    {WALLET_NAME.map((wallet, index) => (
                      <div key={index} className="connector">
                        <div className="connect">
                          <LoadingButton
                            loading={loading === index}
                            onClick={async () => {
                              setLoading(index);
                              try {
                                await connect(wallet.name);
                              } catch (err) {
                                console.log('something went wrong');
                              }
                              setLoading(-1);
                            }}
                            variant="outlined"
                            className="button"
                          >
                            {loading !== index && (
                              <>
                                <img
                                  className="wallet-image"
                                  src={require(`../../assets/wallet/${wallet.image}`)}
                                  alt={wallet.name}
                                />
                                <div className="description">
                                  <span className="title">
                                    {wallet.name} Wallet
                                  </span>
                                </div>
                              </>
                            )}
                          </LoadingButton>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }),
);

export const ConnectForm = memo(ConnectFormComponent);
