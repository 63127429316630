import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  visibility: hidden;
  .menu-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    height: 60vh;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .menu-item {
      cursor: pointer;
      transition: all 0.3s;
      width: 100%;
      padding: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: ${(props) => props.theme.text1};
      background-color: #2b2e39;
      &:hover {
        opacity: 0.9;
        transform: scale(1.1);
        color: #ffffff;
      }
    }
  }
`;
