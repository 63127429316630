import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .btn {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .btn-prev {
    left: 20px;
    ${(props) => props.theme.mediaWidth.upToMedium`
    left: -120px;
      
      `}
    &:hover {
      transform: translate(0%, -50%) scale(1.05);
    }
    &:active {
      transform: translate(0%, -50%) scale(0.95);
    }
  }
  .btn-next {
    right: 20px;
    ${(props) => props.theme.mediaWidth.upToMedium`
    right: -120px;
      
      `}
    transform: rotate(180deg) translate(0%, 50%);
    &:hover {
      transform: rotate(180deg) translate(0%, 50%) scale(1.05);
    }
    &:active {
      transform: rotate(180deg) translate(0%, 50%) scale(0.95);
    }
  }
`;
